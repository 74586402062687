const socketConfig = {
    CashRegularTexas: 'cash_regular_texas',
    CashSngTexas: 'cash_sng_texas',
    CashTexas: 'cash_texas',
    CashOmaha: 'cash_omaha',
    CashPLO5: 'cash_PLO5',
    CashRegularOmaha: 'cash_regular_omaha',
    CashSngOmaha: 'cash_sng_omaha',
    CashSngPLO5: 'cash_sng_PLO5',
    CashRegularPLO5: 'cash_regular_PLO5',
}
export default socketConfig