import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import socket from '../../socket';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const lang = require('../../Config/lang.js');

const INITIAL_STATE = {
  errorMsg: '',
  oldPassword: '',
  newPassword: '',
  verifyNewPassword: ''
};

const VIPLevel = (props) => {
  const [form, setForm] = useState(INITIAL_STATE);
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  const [userProfilePhoto, setUserProfilePhoto] = useState(userData ? userData.avatar : 0);
  const [userProfilePicOption, setUserProfilePicOption] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
  const [userProfileForm, setUserProfileForm] = useState(userData);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [gameHistoryList, setGameHistoryList] = useState({})
  const [gameHistory, setGameHistory] = useState({})
  const [page, setPage] = useState(1);
  const [isGameHistory, setIsGameHistory] = useState(false)
  const [isEditProfile, setIsEditProfile] = useState(false)
  const [preFlop, setPreFlop] = useState({});
  const [flop, setFlop] = useState({});
  const [turn, setTurn] = useState({});
  const [river, setRiver] = useState({});
  const [showDown, setShowDown] = useState({});

  const [isActive, setIsActive] = useState({
    table: 'profile'
  })

  const [isActiveHand, setIsActiveHand] = useState({
    table: 'game'
  })

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
  }, [window.innerWidth]);


  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleProfileChange = (event) => {
    setUserProfileForm({
      ...userProfileForm,
      [event.target.id]: event.target.value,
    });
  };

  const playerProfilePhoto = (img) => {
    return '../Images/Avtar/' + (img + 1) + '.png'
  }

  const changePage = (value) => {
    setPage(value)
    const data = {
      playerId: userData.playerId,
      pageNo: value,
      productName: 'PokerHills'
    }
    socket.emit('playerGameHistory', data, (res) => {
      //console.log(res.result);
      setGameHistoryList(res.result.gamesHistoryList);
    });
  }

  const changePlayerAvtar = (type) => {
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        profilePic: userProfilePhoto,
      }
      socket.emit('playerProfilePic', data, (res) => {
        if (res.status == 'success') {
          let gamePlayer = userData;
          gamePlayer.profilePic = userProfilePhoto
          localStorage.setItem('user', JSON.stringify(gamePlayer));
          props.refreshData();
          closeModal();
        }
      });
    } else {
      setUserProfilePhoto(userData.avatar)
      closeModal();
    }
  }

  const changePlayerProfile = (type) => {
    const data = {
      playerId: userData.playerId,
      firstname: userProfileForm.firstname,
      lastname: userProfileForm.lastname,
      email: userProfileForm.email,
      mobile: userProfileForm.mobile,
    }
    socket.emit('changePlayerProfile', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        socket.emit('Playerprofile', {
          playerId: userData.playerId,
          productName: 'PokerHills'
        }, (res) => {
          localStorage.setItem('user', JSON.stringify(res.result));
          props.refreshData();
          setIsEditProfile(false);
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }

  const changePassword = (type) => {
    form.errorMsg = '';
    if (type == 0) {
      const data = {
        playerId: userData.playerId,
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        verifyNewPassword: form.verifyNewPassword,
      }
      socket.emit('playerChangePassword', data, (res) => {
        if (res.status == 'success') {
          toast.success(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setForm({
            oldPassword: '',
            newPassword: '',
            verifyNewPassword: '',
          });
          closeModal();
        } else {
          toast.error(res.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      });
    } else {
      closeModal();
    }
  }

  const logout = (e) => {
    localStorage.removeItem("user");
    navigate('/')
  }

  const closeModal = () => {
    props.closeMyProfile()
  }

  const openModal = () => {
    setIsLogoutModalOpen(true);
  }
  const closeLogoutModal = () => {
    setIsLogoutModalOpen(false);
  }

  const hideShowDiv = (id) => {
    if (id == 'handHistory') {
      setIsGameHistory(false);
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'PokerHills'
      }
      socket.emit('playerGameHistory', data, (res) => {
        //console.log(res.result);
        setGameHistoryList(res.result.gamesHistoryList);
      });
      setIsActive({
        table: 'gameplayer',
      })
    }
    setIsActive({
      table: id,
    })
  }

  const hideShowHandTab = (id) => {
    setIsActiveHand({
      table: id,
    })
  }

  const openGameHistory = (gameId) => {
    const data = {
      playerId: userData.playerId,
      gameId: gameId,
      productName: 'PokerHills'
    }
    socket.emit('GameHistory', data, (res) => {
      if (res.result) {
        setIsGameHistory(true);
        hideShowHandTab('gameplayer')
        setGameHistory(res.result.gameHistory);
        const data = res.result.gameHistory;
        let preFlopArr = [];
        let flopArr = [];
        let turnArr = [];
        let riverArr = [];
        let showDownArr = [];
        data.handsEvents.map(((evnt, index) => {
          if (evnt.gameRound == 'Preflop') {
            preFlopArr.push(evnt);
          }
          if (evnt.gameRound == "Flop") {
            flopArr.push(evnt);
          }
          if (evnt.gameRound == 'Turn') {
            turnArr.push(evnt);
          }
          if (evnt.gameRound == 'River') {
            riverArr.push(evnt);
          }
          if (evnt.gameRound == 'ShowDown') {
            showDownArr.push(evnt);
          }
        }));
        setPreFlop(preFlopArr);
        setFlop(flopArr);
        setTurn(turnArr);
        setRiver(riverArr);
        setShowDown(showDownArr);
      }
    });
  }

  const mobile = window.innerWidth <= 1024;
  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '40%',
      border: 'none',
      padding: '0px',
      borderRadius: '15px',
    },
  }
  if (mobile) {
    customStyles = {
      content: {
        inset: '44% auto auto 49.5%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#474B6B',
        width: '90%',
        border: 'none',
        padding: '0px',
      },
    }
  }

  return (
    <div >
      <ToastContainer />
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className='plyerProfile'>
          <div className={mobile ? "mobileView" : ''}>
            <div className="cashier-modal viplevel">
              <div className='d-flex min-h-100'>
                <div className='p-col-12 min-h-100 theme-bg'>
                  <div className='cashier-modal-card'>
                    <div className='cashier-modal-heading'>
                      <h2 >{lang.ko.vip_level}</h2>
                      <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                    </div>
                    <div className='cashier-modal-body'>
                      <div className='profile'>
                        <div className='playerProfileInfo cashier-box'>
                          <Row>
                            <Col lg={12}>
                              <div className='plyerProfileAvtar'>
                                <div className={'playerProfileVipWithFrame vipLevel' + userData.vipLevel}>
                                  <img src={playerProfilePhoto(userProfilePhoto)} className='plyrProfilePicMain'></img>
                                </div>
                              </div>
                              <div className='vip-level-section text-center mt-2'>
                                <h4>VIP Level : <span>{userData.vipLevel == 5 ? 'Diamond' : userData.vipLevel == 4 ? 'Platinum' : userData.vipLevel == 3 ? 'Gold' : userData.vipLevel == 2 ? 'Silver' : 'Bronze'}</span></h4>
                              </div>
                            </Col>
                          </Row>
                          <div className='d-flex justify-content-center gap-4 mt-4'>
                            <div className='text-center'>
                              <img src={'../Images/level/1.png'} className={userData.vipLevel == 1 ? 'p-2 framePic activeProfileFrame' : 'p-2 framePic'}></img>
                              <h5 className={userData.vipLevel == 1 ? "activeFrameName" : 'FrameName'}>{lang.ko.bronze}</h5>
                            </div>
                            <div className='text-center'>
                              <img src={'../Images/level/2.png'} className={userData.vipLevel == 2 ? 'p-2 framePic activeProfileFrame' : 'p-2 framePic'}></img>
                              <h5 className={userData.vipLevel == 2 ? "activeFrameName" : 'FrameName'}>{lang.ko.silver}</h5>
                            </div>
                            <div className='text-center'>
                              <img src={'../Images/level/3.png'} className={userData.vipLevel == 3 ? 'p-2 framePic activeProfileFrame' : 'p-2 framePic'}></img>
                              <h5 className={userData.vipLevel == 3 ? "activeFrameName" : 'FrameName'}>{lang.ko.gold}</h5>
                            </div>
                            <div className='text-center'>
                              <img src={'../Images/level/4.png'} className={userData.vipLevel == 4 ? 'p-2 framePic activeProfileFrame' : 'p-2 framePic'}></img>
                              <h5 className={userData.vipLevel == 4 ? "activeFrameName" : 'FrameName'}>{lang.ko.platinum}</h5>
                            </div>
                            <div className='text-center'>
                              <img src={'../Images/level/5.png'} className={userData.vipLevel == 5 ? 'p-2 framePic activeProfileFrame' : 'p-2 framePic'}></img>
                              <h5 className={userData.vipLevel == 5 ? "activeFrameName" : 'FrameName'}>{lang.ko.diamond}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal >
    </div >
  );
};

export default VIPLevel

