import './index.css'

function Jackpot({ roomId, currentRoomId, roomName, playerName, jackpotAmount, handName }) {
  if (!playerName || !jackpotAmount || !handName) {
    return null;
  }
  if(currentRoomId && currentRoomId===roomId)
  {
    return(
      <div className='jackpotMessage'>
        <div className="jp-wrapper">
          <div className="jp-info">
            <div className="jp-amount" data-shadow={jackpotAmount}>{jackpotAmount}</div>
            <div className="jp-pname">{playerName}</div>
            <div className="jp-hname" data-shadow={handName}>{handName}</div>
          </div>
        </div>
      </div>
    )
  }
  else
  {
    return(
      <div className='jackpotMessageBottom'>{roomName}방 {playerName}님 {handName} 잭팟 {jackpotAmount}원 당첨 축하드립니다.</div>  
    )
  }
}

export default Jackpot;