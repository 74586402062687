import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingScreen from './Screens/LandingScreen';
import LobbyScreen from './Screens/LobbyScreen';
import GameScreen from './Screens/GameScreen';
import { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import ErrorBoundary from './Components/ErrorBoundary';

function BuggyComponent() {
  //throw new Error('This is a forced error!');
}
function App() {
  return (
    <ErrorBoundary>
      <HashRouter >
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/:type/:code" element={<LandingScreen />} />
          <Route path="/game/:tableId" element={<GameScreen />} />
          <Route path="/lobby" element={<LobbyScreen />} />
        </Routes>
        <BuggyComponent />
      </HashRouter >
    </ErrorBoundary>
    
  );
}

export default App;




